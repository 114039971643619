<template>
  <HeaderEvent :event="event" :organizer_id="event.organizer.id" :organizer_slug="event.organizer.slug" :image="event.image"/>
  <div class="container-fluid body">
    <div class="container">
      <Loader :show="loading"/>
      <div class="row body-box body-evento">
        <div class="col-md-8">
          <h1 id="h1_titulo">{{event.name}}</h1>
          <ul>
            <li class="w50">
              <h4><span class="glyphicon glyphicon-stats glyphcolor4"></span> STATUS</h4>
              <h5 id="h5_status" v-html="text_status(event)"></h5>
              <h4><span class="glyphicon glyphicon-map-marker glyphcolor4"></span> LOCAL</h4>
              <h5 id="h5_local">{{ event.local == null ? 'Em Breve':event.local }}</h5>
              <h4><span class="glyphicon glyphicon-time glyphcolor4"></span> LARGADA</h4>
              <h5 id="h5_largada">{{ event.largada == null ? 'Em Breve':event.largada }}</h5>
              <h4><span class="glyphicon glyphicon-ok-circle glyphcolor4"></span> MODALIDADES</h4>
              <h5 id="h5_modalidades">{{modalidades}}</h5>
            </li>
            <li class="w50">
              <h4>Faltam para abertura das inscrições:</h4>
              <Countdown :end_time="event.subscription_end" :start_time="event.subscription_start" />
              <h4></h4>
              <div class="btn-full">

                <div id="btn_insc" v-if="(new Date(event.subscription_end)) >= (new Date()) && (new Date(event.subscription_start)) <= (new Date())">
                  <div class="col-xs-6 no-padding">
                    <v-link v-if="user_logged" class="btn btn-success" width="100%" :href="'/escolha-o-kit/'+ event.id +'/'+ event.slug +'/'">Inscreva-se</v-link>
                    <v-link v-else class="btn btn-success" width="100%" :href="'/inscricao/'+ event.id +'/'+ event.slug +'/'">Inscreva-se</v-link>
                  </div>
                  <div class="col-xs-6 no-padding">
                    <button type="button" class="btn btn-info" @click="show_regulamentos">Regulamento</button>
                  </div>
                </div>

                <div v-else-if="(new Date(event.subscription_end)) < (new Date())">
                  <div class="col-xs-12 no-padding">
                    <button type="button" class="btn btn-info" @click="show_regulamentos">Regulamento</button>
                  </div>
                </div>

                <div v-else-if="(new Date(event.subscription_start)) > (new Date())">
                  <div class="col-xs-12 no-padding text-center">
                    Evento em breve
                  </div>
                </div>

                <h6 id="h5_data" class="text-center">Inscrições até {{ (new Date(event.subscription_end)).toLocaleString("pt-BR", {timeZone: "America/Sao_paulo"}) }} ou o término das vagas.</h6>
              </div>
            </li>
          </ul>
          <div id="div_conteudo">
            <div class="evento" v-if="event.description">
              <h2>Sobre o evento</h2>
              <p v-html="event.description"> </p>
            </div>
            <div class="kits" v-if="event.kits">
              <h2>Kits</h2>
              <p v-html="event.kits"> </p>
            </div>
            <div class="percurso" v-if="event.course">
              <h2>Percurso</h2>
              <p v-html="event.course"> </p>
            </div>
            <div class="awards" v-if="event.awards">
              <h2>Premiação</h2>
              <p v-html="event.awards"> </p>
            </div>
            <div class="complementary" v-if="event.complementary">
              <h2>Complementar</h2>
              <p v-html="event.complementary"> </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <p id="div_capa2">
            <img :src="event.image" class="capa-evento-view" :alt="event.name">
          </p>
          <div id="div_redes"></div>
          <h4>Evento organizado por:</h4>
          <p id="p_organizador">
            {{ event.organizer.razao_social }}
            <br>
            CNPJ: {{ event.organizer.cnpj }}
            <br>
            {{ event.organizer.telephone }}
          </p>
          <h6>As informações disponibilizadas nesta página, a organização do evento e a sua realização, são de responsabilidades do organizador. Para mais informações, leia nosso Termo de Uso.</h6>
          <div id="div_patrocinadores" v-if="event.sponsor.length">
            <h5><b>Patrocínio</b></h5>
            <p class="box-patrocinadores">
              <a :href="sponsorItem.link" target="_blank" v-for="(sponsorItem,index) in event.sponsor" :key="index">
                <img :src="sponsorItem.image" >
              </a>
            </p>
          </div>
          <p class="clear">&nbsp;</p>
          <FacebookSide/>
        </div>
        <Atalhos/>
      </div>
    </div>
  </div>

  <div class="modal fade in regulamentos" tabindex="-1" role="dialog" style="display: block;" v-if="modalregulation"  @click="modalregulation= false">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content" @click.stop="">
        <div class="modal-header text-center">
          <button type="button" class="close" @click="modalregulation= false" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Regulamento</h4>
        </div>
        <div class="modal-body" v-html="regulation">
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
  <div class="modal-backdrop fade in" v-if="modalregulation" ></div>

  <Newsletter/>
  <Footer/>
  <FooterEnd/>
</template>

<script>
import {defineComponent} from "vue";
import HeaderEvent from "../components/HeaderEvent";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import Atalhos from "@/components/Atalhos";
import Events from "@/api/Events";
import FacebookSide from "@/components/FacebookSide.vue";
import Loader from "../components/Loader";
import VLink from "../components/VLink";
import Countdown from "../components/Countdown";
import Auth from "../api/Auth";

export default defineComponent({
  name: "EventoPage",
  components: {
    VLink,
    HeaderEvent,
    Footer,
    FooterEnd,
    Newsletter,
    Atalhos,
    FacebookSide,
    Loader,
    Countdown
  },
  computed: {
    modalidades: function () {
      let modalidades = "";
      this.event.modalities.map((item)=>{
        modalidades = item.name +  ', ' + modalidades;
      })
      return modalidades;
    }
  },
  data() {
    return {
      modalregulation:false,
      regulation:"",
      id:'',
      slug:'',
      loading:false,
      "user_logged":false,
      event:{
        "id": 0,
        "subscription_end": "",
        "subscription_start":"",
        "largada": "",
        "name": "",
        "slug": "",
        "local": "",
        "state": "",
        "city": "",
        "description": "",
        "kits": "",
        "course": "",
        "awards": "",
        "complementary":"",
        "organizer": {
          "id": 0,
          "razao_social": "",
          "slug": "",
          "cnpj": "",
          "telephone": ""
        },
        "modalities": [],
        "banners": [],
        "sponsor":[],
      },
    };
  },
  methods: {
      status(event){
        let today = new Date();
        let start_date = new Date(event.subscription_start);
        let end_date = new Date(event.subscription_end);
        if(start_date <= today && end_date >= today){
          return true;
        }else{
          return false;
        }
      },
    text_status(event){
      let today = new Date();
      let start_date = new Date(event.subscription_start);
      let end_date = new Date(event.subscription_end);
      if (start_date <= today)
      {
        if (end_date >= today)
          return  "<span class=\"text-success\">INSCRIÇÕES ABERTAS</span>";
        else
          return "<span class=\"text-danger\">INSCRIÇÕES ENCERRADAS</span>";
      }
      else
        return "<span class=\"text-warning\">INSCRIÇÕES EM BREVE</span>";

    },
    fetch_info(){
      Events.get_event(this.slug).then((request) => {
        if(request.status == 200){
          this.event = request.data;
        } else {
          this.$router.push("/evento/nao-encontrado/");
        }
        this.loading = false;
      });
    },
    show_regulamentos(){
      if(this.regulation == ""){
        Events.get_regulamento(this.slug).then((request)=>{
          if(request.status == 200) {
            this.modalregulation = true;
            this.regulation = request.data.regulation;
          }
        });
      }else{
        this.modalregulation = true;
      }

    },
  },
  mounted() {
    this.id =  this.$route.params.id;
    this.slug =  this.$route.params.slug;

    if(Auth.token()){
      this.user_logged = true;
    }else{
      this.user_logged = false;
    }

    this.regulation = "";

    this.loading = true;
    this.fetch_info();
  },
  updated() {
    if(Auth.token()){
      this.user_logged = true;
    }else{
      this.user_logged = false;
    }

    if(this.slug != this.$route.params.slug){

      this.regulation = "";
      this.slug = this.$route.params.slug;
      this.loading = true;
      this.fetch_info();
    }
  }
});
</script>

<style scoped>
img.capa-evento-view{
  width: 100%;
}
.box-patrocinadores a {
  width: 100%;
}
.box-patrocinadores a img{
  width: 100%;
}
.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
.no-padding{

  padding-right: 0px;
  padding-left: 0px;
}
.body-box.body-evento .col-md-8{
  z-index: 100;
}

.body-box.body-evento  #div_capa2{
  z-index: 1;
}
</style>
